<template>
  <div id="deliverySite">
    <!-- 进口商 -->
    <div class="con">
      <div
        class="opt"
        v-for="(item, index) in importer"
        :key="index"
        :class="{ acquiescent: item.acquiescent }"
      >
        <h1>
          {{ $fanyi("地址") }}{{ index + 1 }}
          <span v-if="item.acquiescent"> {{ $fanyi("当前默认地址") }} </span>
        </h1>
        <p>
          <label for="">{{ $fanyi("全名") }}：</label>
          <span :title="item.contacts">{{ item.contacts }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi("公司(可选)") }}：</label>
          <span :title="item.company">{{ item.company }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi("邮编") }}：</label>
          <span :title="item.zip_code">{{ item.zip_code }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi("按人口分列的城市") }}：</label>
          <span :title="item.city">{{ item.city }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi("省份") }}：</label>
          <span :title="item.province">{{ item.province }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi("国家") }}：</label>
          <span :title="item.country">{{ item.country }}</span>
        </p>
        <p class="address">
          <label for="">{{ $fanyi("地址") }}：</label>
          <span :title="item.address">{{ item.address }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi("电话") }}：</label>
          <span :title="item.mobile">{{ item.mobile }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi("邮件箱") }}：</label>
          <span :title="item.email">{{ item.email }}</span>
        </p>

        <!--        <p>-->
        <!--          <label for="">{{ $fanyi("电话") }}：</label>-->
        <!--          <span :title="item.tel">{{ item.tel }}</span>-->
        <!--        </p>-->
        <p>
          <label for="">CIF / DNI：</label>
          <span :title="item.cnpj">{{ item.cnpj }}</span>
        </p>
        <!-- 操作 -->
        <div class="btn">
          <button @click="edit(item)">{{ $fanyi("编辑") }}</button>
        </div>
      </div>
      <div class="add opt">
        <div @click="openAddAddressWin">
          <img src="../../../../assets/user-img/tianJia.svg" alt="" />
          <span>{{ $fanyi("新增地址") }}</span>
        </div>
      </div>
    </div>
    <!-- 添加和 修改 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <div class="formBox">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="179px"
          :key="key"
        >
          <el-form-item :label="$fanyi('全名') + '：'" prop="contacts">
            <el-input
              size="mini"
              v-model="form.contacts"
              oninput="this.value"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('公司(可选)') + '：'" prop="company">
            <el-input
              size="mini"
              v-model="form.company"
              oninput="this.value"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('邮编') + '：'" prop="zip_code">
            <el-input
              size="mini"
              v-model="form.zip_code"
              oninput="this.value"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('按人口分列的城市') + '：'" prop="city">
            <el-input
              type="textarea"
              v-model="form.city"
              show-word-limit
              resize="none"
              oninput="this.value"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('省份') + '：'" prop="province">
            <el-input
              type="textarea"
              v-model="form.province"
              show-word-limit
              resize="none"
              oninput="this.value"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('国家') + '：'" prop="country">
            <el-input
              type="textarea"
              v-model="form.country"
              show-word-limit
              resize="none"
              oninput="this.value"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('地址') + '：'" prop="address">
            <el-input
              type="textarea"
              v-model="form.address"
              show-word-limit
              resize="none"
              oninput="this.value"
            >
            </el-input>
          </el-form-item>

          <!--          <el-form-item :label="$fanyi('手机') + '：'" prop="mobile">-->
          <!--            <el-input-->
          <!--                size="mini"-->
          <!--                v-model="form.mobile"-->
          <!--                oninput="this.value"-->
          <!--            ></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item :label="$fanyi('电话') + '：'" prop="mobile">
            <el-input
              size="mini"
              v-model="form.mobile"
              oninput="this.value"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('邮件箱') + '：'" prop="email">
            <el-input
              size="mini"
              v-model="form.email"
              oninput="this.value"
            ></el-input>
          </el-form-item>
          <el-form-item :label="'CIF / DNI ' + '：'" prop="cnpj">
            <el-input
              size="mini"
              v-model="form.cnpj"
              oninput="this.value"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('是否默认') + '：'">
            <el-switch v-model="form.isAcquiescent"> </el-switch>
            <!-- <el-checkbox v-model="form.acquiescent">默认</el-checkbox> -->
          </el-form-item>
        </el-form>
        <div class="btn">
          <!-- 新增 -->
          <p v-if="isAdd">
            <button class="reset" @click="openAddAddressWin">
              {{ $fanyi("重置") }}
            </button>
            <button @click="useraddressAdd">{{ $fanyi("新增") }}</button>
          </p>
          <!-- 删除 -->
          <p class="edit" v-else>
            <button class="del" @click="useraddressDelete">
              {{ $fanyi("删除") }}
            </button>
            <button @click="useraddressUpdate">{{ $fanyi("保存") }}</button>
          </p>

          <!-- <button>保存</button> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 2,
      consignee: [],
      importer: [],
      dialogVisible: false,
      form: {},
      title: "",
      isAdd: false,
      key: "fsddsfa",
      rules: {
        company: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        zip_code: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        address: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        contacts: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        province: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        mobile: [
          // { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
          // {
          //   required: true,
          //   pattern: /^(\w){9}$/,
          //   message: this.$fanyi("手机号输入错误"),
          // },
        ],
        city: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        country: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        tel: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        email: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
          {
            type: "email",
            message: this.$fanyi("请输入正确的邮箱地址"),
            trigger: ["blur", "change"],
          },
        ],
        cnpj: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        company: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        company: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
        company: [
          { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
        ],
      },
    };
  },

  created() {
    this.useraddressList();
  },
  methods: {
    handleClose(done) {
      this.$confirm(this.$fanyi("信息未保存，是否确认退出"))
        .then((_) => {
          this.form = {
            type: this.type,
            company: "",
            zip_code: "",
            address: "",
            city: "",
            country: "",
            contacts: "",
            mobile: "",
            tel: "",
            email: "",
            cnpj: "",
            mark: "",
            province: "",
            acquiescent: 0,
            isAcquiescent: false,
          };
          done();
        })
        .catch((_) => {});
    },
    // 添加地址
    useraddressAdd() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });
      this.form.isAcquiescent
        ? (this.form.acquiescent = 1)
        : (this.form.acquiescent = 0);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.tel = "+34 " + this.form.tel;
          this.$api.useraddressAdd(this.form).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.$message.success(this.$fanyi("保持正确"));
            this.useraddressList();
            this.dialogVisible = false;
          });
        }
      });
    },
    // 编辑地址
    useraddressUpdate() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });
      this.form.isAcquiescent
        ? (this.form.acquiescent = 1)
        : (this.form.acquiescent = 0);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.tel = "+34" + this.form.tel;
          this.$api.useraddressUpdate(this.form).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.$message.success(this.$fanyi("保持正确"));
            this.useraddressList();
            this.dialogVisible = false;
          });
        }
      });
    },
    // 打开新增地址弹窗
    openAddAddressWin() {
      this.dialogVisible = true;
      this.key = Math.random()
        .toString(36)
        .slice(-8);
      this.form = {
        type: this.type,
        company: "",
        zip_code: "",
        address: "",
        contacts: "",
        mobile: "",
        province: "",
        tel: "",
        email: "",
        cnpj: "",
        mark: "",
        acquiescent: 0,
        isAcquiescent: false,
      };
      this.isAdd = true;
      this.title = this.$fanyi("新增地址");
    },
    // 显示编辑框
    edit(item) {
      item.isAcquiescent = item.acquiescent;
      let data = JSON.stringify(item);
      this.form = JSON.parse(data);
      this.title = this.$fanyi("编辑地址");
      this.isAdd = false;
      this.dialogVisible = true;
      this.key = Math.random()
        .toString(36)
        .slice(-8);
    },
    // 删除
    useraddressDelete() {
      let msg = this.$fanyi("是否删除当前地址");
      this.$confirm(msg, this.$fanyi("提示"), {
        confirmButtonText: this.$fanyi("确定"),
        cancelButtonText: this.$fanyi("取消"),
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$api.useraddressDelete({ id: this.form.id }).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.$message.success(this.$fanyi("保持正确"));
            this.useraddressList();
            this.dialogVisible = false;

            // }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$fanyi("删除取消"),
          });
        });
    },
    // // 同步
    // synchronization(item) {
    //   let msg = this.type == 2 ? "收件人" : "进口商";
    //   item.type = this.type == 2 ? 1 : 2;
    //   item.acquiescent = 0;
    //   this.$confirm(this.$fanyi("是否同步到" + msg), this.$fanyi("提示"), {
    //     confirmButtonText: this.$fanyi("确定"),
    //     cancelButtonText: this.$fanyi("取消"),
    //     type: "warning",
    //     center: true,
    //   }).then(() => {
    //     this.$api.useraddressAdd(item).then((res) => {
    //       if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
    //
    //       this.useraddressList();
    //       this.dialogVisible = false;
    //       this.$message.success(this.$fanyi("保持正确"));
    //     });
    //   });
    // },
    // 获取数据
    useraddressList() {
      this.consignee = [];
      this.importer = [];
      this.$api.useraddressList().then((res) => {
        // //console.log("useraddressList", res);

        if (res.data.consignee) {
          this.consignee = res.data.consignee;
        }
        if (res.data.importer) {
          this.importer = res.data.importer;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../css/mixin";
#deliverySite {
  background-color: #f8f7f7;
  nav {
    display: flex;
    span {
      display: block;
      width: 130px;
      height: 50px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      border-bottom: none;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
      &.active {
        background: #ffffff;
      }
    }
  }
  .con {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    padding: 30px;
    > .opt {
      width: 319px;
      min-height: 310px;
      background: #f6f6f6;
      border: 1px solid $homePageSubjectColor;
      border-radius: 10px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-right: 10px;
      margin-bottom: 10px;
      //&:nth-child(3n) {
      //  margin-right: 0;
      //}
      h1 {
        font-size: 18px;

        font-weight: bold;
        color: #000000;
        margin-bottom: 30px;
        position: relative;
        span {
          position: absolute;
          top: -25px;
          right: -20px;
          padding: 0 10px;
          text-align: right;
          // width: 100px;
          height: 25px;
          background: #ffebce;
          border: 1px solid #ffa724;
          border-radius: 10px;
          font-size: 12px;
          text-align: center;
          color: #ffa724;
          line-height: 25px;
        }
      }
      p {
        display: flex;
        margin-bottom: 14px;
        height: 14px;
        label {
          display: inline-block;
          flex: 0 0 120px;
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          margin-right: 10px;
        }
        span {
          display: inline-block;
          height: 15px;
          width: 200px;
          display: block;
          font-weight: 400;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.address {
          max-height: 35px;
          display: flex;
          span {
            height: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            // 下面这句用来控制行数
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
      .btn {
        text-align: center;
        margin-top: 10px;
        display: flex;
        align-items: center;
        button {
          height: 40px;
          border-radius: 4px;
          font-size: 12px;
          padding: 0 10px;
          line-height: 14px;
          margin-right: 20px;
          &:nth-last-child(1) {
            margin: 0;
          }
        }
      }
      &.add {
        background: #f6f6f6;
        border: none;
        text-align: center;
        height: 430px;
        > div {
          cursor: pointer;
        }
        img {
          width: 70px;
          height: 70px;
          display: block;
          margin: 0 auto 15px;
        }
        span {
          font-size: 18px;
          font-weight: bold;
          color: #9c9c9c;
        }
      }
    }
    .acquiescent {
      background-color: #fcfcff;
    }
  }
}
.btn {
  text-align: center;
  button {
    cursor: pointer;
    height: 40px;
    border: 1px solid $subjectColor;
    font-size: 16px;
    width: 130px;
    border-radius: 4px;
    color: #fff;
    background-color: $subjectColor;
  }
  .reset {
    color: $subjectColor;
    background-color: #fff;
    margin-right: 40px;
  }
  .edit {
    .del {
      background-color: #bd4747;
      border-color: #bd4747;
      margin-right: 40px;
    }
  }
}
.formBox {
  padding: 0 40px;
}
/deep/.el-dialog__header {
  border-bottom: 1px solid #dedede;
  .el-dialog__title {
    color: #000;
    font-weight: bold;
    font-size: 18px;
  }
  .el-icon-close {
    font-size: 22px;
    color: #000;
    font-weight: bold;
  }
}
/deep/.el-form-item {
  margin-bottom: 20px;
  .el-form-item__label {
    line-height: 25px;
  }
}
/deep/.el-checkbox__input {
  height: 16px;
}
</style>
